import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import api from '../../services/api';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Button, LinearProgress } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import { useQueryParam } from 'use-query-params';
import GeneralErrors from '../../components/form/general-errors';
import LayoutPublic from '../../components/layout/public';
import { navigate } from 'gatsby';

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function RecoveryConfirmPage() {
  const classes = useStyles();

  const [errors, setErrors] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [changed, setChanged] = useState();
  const [password1, setPassword1] = useState();
  const [password2, setPassword2] = useState();
  const [recoveryId] = useQueryParam("id");

  useEffect(() => {
    if (!recoveryId) return;

    (async () => {
      setLoading(true);

      try {
        const response = await api.get(`/auth/recovery/${recoveryId}`);
        setData(response.data);
      } catch (e) {
        setErrors(e?.response?.data || 'Erro desconhecido');
      }

      setLoading(false);
    })();

  }, [recoveryId]);

  const send = async () => {
    setErrors(undefined);

    const errors = [];

    if (!password1) {
      errors.push('Informe a nova senha.');
    } else if (!password2) {
      errors.push('Confirme a nova senha.');
    } else if (password1 !== password2) {
      errors.push('As senhas digitadas são diferentes.');
    }

    if (errors.length > 0) {
      return setErrors(errors);
    }

    setLoading(true);

    try {
      await api.post(`/auth/recovery/${recoveryId}`, {
        password: password1,
      });
      setChanged(true);
    } catch (e) {
      setErrors(e?.response?.data || 'Erro desconhecido');
    }

    setLoading(false);
  };

  return (
    <LayoutPublic>
      {loading ? <LinearProgress /> : (
        <Container component="main" maxWidth="md">
          <>
            {changed ? (
              <Alert severity="success">
                <AlertTitle>Senha alterada com sucesso</AlertTitle>
                <span style={{ textDecoration: 'underline', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => navigate('/login')}>Clique aqui para entrar no sistema.</span>
              </Alert>
            ) : (
              <>
                {!data && errors && <GeneralErrors errors={errors} />}
                {data && (
                  <>
                    <h3>Olá {data?.name}.</h3>
                    <p style={{
                      lineHeight: 1.5,
                      fontSize: isMobile ? 16 : 18
                    }}>
                      Altere a senha do usuário "{data?.email}".
                    </p>

                    <TextField
                      label="Nova senha"
                      type="password"
                      autoComplete="current-password"
                      variant="outlined"
                      margin="normal"
                      onChange={(e) => setPassword1(e.target.value)}
                      required
                      fullWidth
                    />

                    <TextField
                      label="Confirmar senha"
                      type="password"
                      autoComplete="current-password"
                      variant="outlined"
                      margin="normal"
                      onChange={(e) => setPassword2(e.target.value)}
                      required
                      fullWidth
                    />

                    {errors && (<GeneralErrors errors={errors} />)}

                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disabled={loading}
                      onClick={send}
                    >Alterar senha</Button>
                  </>
                )}
              </>
            )}
          </>
        </Container>
      )}
    </LayoutPublic>
  );

}